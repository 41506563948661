const daysValue = document.querySelector('[data-days]');
const hoursValue = document.querySelector('[data-hours]');
const minutesValue = document.querySelector('[data-minutes]');
const secondsValue = document.querySelector('[data-seconds]');

var countDownDate = new Date('Jan 05, 2024 20:00:00').getTime();

// Update the count down every 1 second
var x = setInterval(function () {
  // Get today's date and time
  var now = new Date().getTime();
  // Find the distance between now and the count down date
  var distance = countDownDate - now;
  // Time calculations for days, hours, minutes and seconds
  var days = Math.floor(distance / (1000 * 60 * 60 * 24));
  var hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
  var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
  var seconds = Math.floor((distance % (1000 * 60)) / 1000);

  // Display the result in the element
  const addLeadingZero = function (value) {
    return value.toString().padStart(2, '0');
  };
  daysValue.textContent = addLeadingZero(days);
  hoursValue.textContent = addLeadingZero(hours);
  minutesValue.textContent = addLeadingZero(minutes);
  secondsValue.textContent = addLeadingZero(seconds);

  // If the count down is finished, write some text
  if (distance <= 1) {
    clearInterval(x);
    daysValue.textContent = '00';
    hoursValue.textContent = '00';
    minutesValue.textContent = '00';
    secondsValue.textContent = '00';
    document.getElementById('infoTimePL').innerHTML =
      'Zapisy rozpoczęte!<br /><p><a href="https://practiscore.com/benelli-shotgun-marynino-2024/register">LINK DO REJESTRACJI BSM2024</a></p>';
    document.getElementById('infoTimeEN').innerHTML =
      'Registration has started!<br /><p><a href="https://practiscore.com/benelli-shotgun-marynino-2024/register">KLIK TO REGISTER BSM2024</a></p>';
  }
}, 1000);
